import { compact } from 'lodash'

import { findThumbnail } from '@marketplace-web/shared/utils'
import { ConversationMessageType } from 'constants/conversation'
import { AdditionalTransactionAction, ApplicableTransactionAction } from 'constants/transaction'

import {
  ConversationEducationDto,
  ConversationEntityDto,
  ConversationStatusMessageDto,
  ConversationOfferMessageDto,
  ConversationOfferRequestMessageDto,
  ConversationMessageDto,
  ConversationOppositeUserDto,
  ConversationTransactionDto,
  ConversationModeratedItemDto,
  ConversationDto,
} from 'types/dtos'
import {
  ConversationTransactionModel,
  ConversationEducation,
  ConversationMessageEntity,
  StatusMessage,
  OfferMessage,
  OfferRequestMessage,
  ConversationMessage,
  OppositeUser,
  ConversationModeratedItem,
  Message,
  Conversation,
} from 'types/models'

import { transformCurrencyAmountDto } from './currency-amount'
import { transformItemPhotoDto } from './item'
import { transformPhotoDto } from './photo'

const transformEducationDto = (education: ConversationEducationDto): ConversationEducation => {
  const { action, action_title, screen_title, type, url } = education

  return {
    action,
    actionTitle: action_title,
    screenTitle: screen_title,
    type,
    url,
  }
}

const transformMessage = (message: ConversationEntityDto): ConversationMessageEntity => {
  switch (message.entity_type) {
    case ConversationMessageType.ActionMessage:
    case ConversationMessageType.StatusMessage: {
      const entity = message.entity as ConversationStatusMessageDto

      return {
        style: entity.template.style,
        title: entity.title,
        subtitle: entity.subtitle,
        notification: entity.notification,
        actions: entity.actions,
        eventType: entity.event_type,
      } as StatusMessage
    }
    case ConversationMessageType.OfferMessage: {
      const entity = message.entity as ConversationOfferMessageDto

      return {
        id: message.id,
        note: entity.note,
        price: entity.price,
        isPriceFixed: entity.price_fixed,
        originalPrice: entity.original_price,
        currency: entity.currency,
        isCurrentOffer: entity.current,
        userId: entity.user_id.toString(),
      } as OfferMessage
    }
    case ConversationMessageType.OfferRequestMessage: {
      const entity = message.entity as ConversationOfferRequestMessageDto

      return {
        userId: entity.user_id.toString(),
        status: entity.status,
        price: entity.price,
        isPriceFixed: entity.price_fixed,
        originalPrice: entity.original_price,
        currency: entity.currency,
        statusTitle: entity.status_title,
        offerRequestId: entity.offer_request_id.toString(),
        isCurrentOffer: entity.current,
        title: entity.title,
        body: entity.body,
        transactionId: entity.transaction_id.toString(),
      } as OfferRequestMessage
    }
    case ConversationMessageType.Message: {
      const entity = message.entity as ConversationMessageDto

      return {
        id: entity.id,
        content: entity.body,
        isSentFromMobile: entity.sent_via_mobile,
        photos: entity.photos.map(transformPhotoDto),
        isHidden: entity.is_hidden,
        userId: String(entity.user_id),
        reaction: entity.reaction,
      } as Message
    }
    case ConversationMessageType.PortalMessage: {
      const entity = message.entity as ConversationMessageDto

      return {
        id: entity.id,
        content: entity.body,
        isSentFromMobile: entity.sent_via_mobile,
        photos: entity.photos.map(transformPhotoDto),
        isHidden: entity.is_hidden,
      } as Message
    }
    case ConversationMessageType.ReportSuggestion:
    default:
      return null
  }
}

const transformMessageEntity = (message: ConversationEntityDto): ConversationMessage | null => ({
  createdTime: message.created_at_ts,
  createdRelativeTime: message.created_time_ago,
  entityType: message.entity_type,
  message: transformMessage(message),
})

const transformConversationOppositeUser = (
  oppositeUser: ConversationOppositeUserDto,
): OppositeUser => ({
  id: oppositeUser.id.toString(),
  city: null,
  localCountryTitle: '',
  isLocationExposed: null,
  login: oppositeUser.login,
  lastLoginTime: oppositeUser.last_logged_in_at,
  feedbackCount: null,
  reviewCount: oppositeUser.review_count,
  feedbackReputation: oppositeUser.feedback_reputation,
  profileUrl: oppositeUser.profile_url,
  photo: oppositeUser.photo ? transformPhotoDto(oppositeUser.photo) : null,
  photoThumbnail: findThumbnail(oppositeUser.photo),
  isSystem: oppositeUser.is_system,
  isHated: oppositeUser.is_hated,
  locationDescription: oppositeUser.location_description,
  isUserUnblockable: oppositeUser.is_user_unblockable,
})

const transformConversationTransaction = (
  transaction: ConversationTransactionDto,
): ConversationTransactionModel => ({
  id: transaction.id,
  order: {
    reserved: transaction.is_reserved,
    itemIds: transaction.item_ids,
    title: transaction.item_title,
  },
  buyer: {
    id: transaction.buyer_id,
    business: null,
  },
  userSide: transaction.current_user_side,
  shipment: transaction.shipment_id
    ? {
        id: transaction.shipment_id,
        status: transaction.shipment_status,
        packageType: {
          packageSizeCode: transaction.package_size_code,
        },
        price: transaction.shipment_price ? transaction.shipment_price.amount : null,
        currency: transaction.shipment_price ? transaction.shipment_price.currency_code : null,
      }
    : null,
  canMarkAsReceived: transaction.available_actions.includes(
    AdditionalTransactionAction.MarkAsReceived,
  ),
  availableActions: transaction.available_actions,
  itemsCount: transaction.item_count,
  itemId: transaction.item_id,
  isItemVerified: transaction.offline_verification,
  offer: {
    id: transaction.offer_id,
    price: transformCurrencyAmountDto(transaction.offer_price),
  },
  status: transaction.status,
  itemTitle: transaction.item_title,
  seller: transaction.seller_id
    ? {
        id: transaction.seller_id,
        business: transaction.is_business_seller,
      }
    : null,
  shippingOrderId: transaction.shipping_order_id,
  isBundle: transaction.is_bundle,
})

const transformConversationModeratedItemDto = (
  moderatedItem: ConversationModeratedItemDto,
): ConversationModeratedItem => ({
  id: moderatedItem.id,
  title: moderatedItem.title,
  status: moderatedItem.status,
  availabilityStatus: moderatedItem.availability_status,
  size: moderatedItem.size,
  photoUrl: moderatedItem.photo_url,
})

export const transformConversationResponse = (response: ConversationDto): Conversation => ({
  id: response.id,
  messages: compact(response.messages.map(transformMessageEntity)),
  transactionId: response.transaction?.id,
  isTranslated: response.translated,
  oppositeUser: response.opposite_user && transformConversationOppositeUser(response.opposite_user),
  buyerId: response.transaction?.buyer_id,
  isPaymentActionAvailable:
    response.transaction?.available_actions.includes(
      ApplicableTransactionAction.Buy ||
        ApplicableTransactionAction.Offer ||
        ApplicableTransactionAction.RequestOffer,
    ) ?? false,
  translationType: response.localization,
  isReadByOppositeUser: response.read_by_opposite_user,
  itemId: response.transaction?.item_id,
  isReplyAllowed: response.allow_reply,
  isSuspicious: response.is_suspicious,
  isDeletionRestricted: response.is_deletion_restricted,
  isBundleAvailable:
    response.transaction?.available_actions.includes(AdditionalTransactionAction.Bundle) ?? false,
  transaction: response.transaction ? transformConversationTransaction(response.transaction) : null,
  suggestedMessages: response.suggested_messages,
  item: response.transaction && {
    id: response.transaction.item_id,
    priceNumeric: response.transaction.offer_price.amount,
    currency: response.transaction.offer_price.currency_code,
    url: response.transaction.item_url,
    photo: response.transaction.item_photo
      ? transformItemPhotoDto(response.transaction.item_photo)
      : null,
    title: response.transaction.item_title,
  },
  education: response.education ? transformEducationDto(response.education) : null,
  moderatedItems: response.moderated_items
    ? {
        itemCount: response.moderated_items.item_count,
        items: response.moderated_items.items.map(transformConversationModeratedItemDto),
      }
    : null,
  isControl: false,
  userHasSupportRole: false,
  isFirstTimeListerEducationRequired: response.safety_education ?? false,
  isItemPriceUpdatable: response.is_item_price_updatable,
  isOrderDetailsEnabled: response.order_details_enabled,
})
